<template>
  <div class="hello">
    <h1>vue ajax 请求测试</h1>
    <input @click="handleAjax"  type="button" value="ajax请求">
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  methods: {
    handleAjax() {
      const fetchUrl = 'https://web-oss-zb1.oss-cn-chengdu.aliyuncs.com/data.json'
      console.log("click handleAjax....")
      axios.get(fetchUrl).then(res  => {
        console.log('success:', res)
      }).catch(err => {
        console.log('err:', err)
      })
    }
  }
}
</script>
<style scoped>

</style>
